// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { WorkspaceProps } from "./WorkspaceListController.web";
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { handleAuthenticationError } from "../../../components/src/helper";

export const configJSON = require("./config.js");

export type Props =  {
    navigation: any;
    id: string;
    classes: Record<string, string>
}

interface S {
  view: number;
  currentWorkspace?: WorkspaceProps
  currentUserId: string
  loading: boolean
}

interface SS {
  id: string | number;
}

class DetailWorkspaceTabViewController extends BlockComponent<Props, S, SS> {
    getDetailWorkspaceId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            view: 0,
            currentUserId: "",
            loading: false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.getDetailWorkspaceId) {
                    this.setState({ currentWorkspace: responseJson, loading: false }, () => {
                        this.saveName()
                    })
                }
            } else {
                if (apiRequestCallId === this.getDetailWorkspaceId && (responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token")) {
                    this.navigateToHome()
                }
            }

            handleAuthenticationError<Props>(message, this.props, this.send)
        }
    }

    saveName = async () => {
        await setStorageData("workspace_name", this.state.currentWorkspace?.workspace_name)
    }

    navigateToHome = () => {
        const msgType: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock"
        );
        msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        removeStorageData("account_id")
        removeStorageData("authToken")
        this.send(msgType);
    }
    
    async componentDidMount(): Promise<void> {
        this.getDetailWorkspace()
        this.getCurrentUserId()
    }

    getCurrentUserId = async () => {
        const account_id = await getStorageData("account_id");
        this.setState({ currentUserId: account_id || "" })
    }

    reloadWorkspace = () => {
        this.getDetailWorkspace()
    }

    getDetailWorkspace = async () => {
        this.setState({ loading: true })
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        
        const idWorkspace = this.props.navigation.getParam("id")
        this.getDetailWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.detailWorkspace}/${idWorkspace}`
        });
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ view: newValue });
    };

    onGobackToWorkspace = () => {
        this.props.navigation.goBack()
    }
}

export default DetailWorkspaceTabViewController;
// Customizable Area End
