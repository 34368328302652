// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import * as XLSX from 'xlsx';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { UploadOptions } from "../../../blocks/dashboard/src/DashboardController.web";

export type resultDataType = {
    category?: string;
    id?: string | number;
    amount?: string;
    description?: string;
}

export const configJSON = require("./config.js");

const API_RETRY_COUNT = 3;

export type SelectedOption = {
    name: string
    id: number
}

export type Props = {
    workspaceId: number
    classes?: Record<string, string>
    state?: any
    currentUserId: string
    resetRegenerate?: () => void
    handleSavePDF?: any
}


interface S {
    resultList?: resultDataType[];
    tableList: {
        status: string;
        result: Record<string, string>[]
    }
}

interface SS {
    id: string | number;
}

class ResultTabController extends BlockComponent<Props, S, SS> {
    getTableDataID: string = "";
    getListResultId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            resultList: [],
            tableList: {
                status: "",
                result: []
            }
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    getTableDataAccordingToSelectedOption = async () => {
        this.setState({ tableList: { status: "", result: [] } });

        const aiProcessingModel = this.props.state.selectedAiProcessingModel as UploadOptions || UploadOptions.AIProcessing

        const headers = {
            access_token: aiProcessingModel === UploadOptions.AIProcessing ? configJSON.aiAccess : configJSON.greekAccess,
        };
    
        const fetchData = async (option: SelectedOption, retryCount = API_RETRY_COUNT): Promise<Object | null> => {
            const formData = new FormData();
            formData.append('file', this.props.state.currentFile);

            try {
                const response = await fetch(
                    `${aiProcessingModel === UploadOptions.AIProcessing ? configJSON.aiEndPoint : configJSON.greekEndPoint}${option.name}`,
                    {
                        method: 'POST',
                        headers,
                        body: formData,
                    }
                );
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                return await response.json();
            } catch (error) {
                if (retryCount > 0) {
                    return await fetchData(option, retryCount - 1);
                }
                return null;
            }
        };
    
        try {
            const promises = this.props.state.selectedOptions.map((option: SelectedOption) => fetchData(option));
    
            const results = await Promise.all(promises);
    
            const successfulResults = results.filter((result) => result !== null);
 
            this.setState({ tableList: { status: "", result: successfulResults as Record<string, string>[] } }, () =>  this.handleData());
        } catch (error) {
            this.setState({ tableList: { status: "error", result: [] } });
        }
    };

    handleGetTableName = (result?: Record<string, string> | Record<string, string>[]) => {
        if (result) {
            if (Array.isArray(result)) {
                return Object.keys(result?.[0])
            } else {
                return Object.keys(result)
            }
        } else return [""]
    }
    traverseAndFlatten = (currentNode: any, target: any, flattenedKey: any) => {
        for (let key in currentNode) {
            if (currentNode.hasOwnProperty(key)) {
                let newKey;
                if (flattenedKey === undefined) {
                    newKey = key;
                } else {
                    newKey = flattenedKey + '.' + key;
                }
    
            const value = currentNode[key];
            if (typeof value === "object") {
                this.traverseAndFlatten(value, target, newKey);
            } else {
                target[newKey] = value;
            }
        }
    }
    }
    
    flatten = (obj: any) => {
        const flattenedObject: any = {};
        this.traverseAndFlatten(obj, flattenedObject, '');
        return flattenedObject;

    }
    getTableRowData = (result?: Record<string, string> | Record<string, string>[]) => {
        if (result) {
         
            if (Array.isArray(result)) {
                return result
            } else {
                return [result]
            }
        } else return []
    }

    async componentDidMount(): Promise<void> {
        this.getTableDataAccordingToSelectedOption()
    }

    // istanbul ignore next
    handleData = () => {
        if (this.props.state.fileFormat === "XLSX") {
            const worksheet = XLSX.utils.json_to_sheet(this.getTableRowData(this.state.tableList.result));
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "AI-review.xlsx");
            const data = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
            this.props.handleSavePDF(new Blob([data]));
        } else {
            const element = document.querySelector("#myTable") as HTMLElement;
            if (element) {
                html2canvas(element, {
                    scale: 2, // Adjust scale for better quality-to-size balance
                }).then((canvas: HTMLCanvasElement) => {
                    const imgData = canvas.toDataURL("image/jpeg", 0.8); // Use JPEG and set quality to 0.8
                    const pdf = new jsPDF();
                    const width = 190;
                    const height = (canvas.height * width) / canvas.width;
                    pdf.addImage(imgData, 'JPEG', 10, 10, width, height, undefined, 'FAST'); // Use 'FAST' compression
                    const pdfBlob = pdf.output('blob');
                    this.props.handleSavePDF(pdfBlob);
                });
            }
        }
    }

    // istanbul ignore next
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (prevProps.state?.fileFormat !== this.props.state.fileFormat) {
            this.handleData()
        }
        if (!prevProps.state?.isRegenerate && this.props.state.isRegenerate) {
            this.getTableDataAccordingToSelectedOption()
        }
        if(prevProps.state?.currentTab === 0 && this.props.state?.currentTab == 1 && this.props.state?.pdf == "") {
            this.handleData()
        }
    }
}

export default ResultTabController;
// Customizable Area End
