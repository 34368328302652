// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  otpMessage: string;
  isresendClicked:boolean;
  otpFactNumber: string;
  isOtpError: boolean;
}

export interface SS {
  id: any;
}

export default class FactorAuthenticateController extends BlockComponent<Props,
  S,SS> {
  
  verifyOtpFactorCallId:any;
  reSendOtpFactorCallId:any
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      otpFactNumber: '',
      isresendClicked:false,
      isOtpError: true,
      otpMessage: "",
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.verifyOtpFactorCallId) {
          this.handleFactorAuthenticationVerifyResp(responseJson)
        }else if (apiRequestCallId === this.reSendOtpFactorCallId) {
          this.handleReFactorAuthenticationVerifyResp(responseJson)
        }
      }
    }

  }

  handleFactorAuthenticationVerifyResp = async (responseJson: any) => {
    if (responseJson?.meta?.message) {
      this.setState({ isOtpError: false, otpMessage: "" })
      const isNewAccount = responseJson.meta.new_account
      setStorageData('new_account', String(isNewAccount))

      const authToken = (await getStorageData("temp_authToken")) as string;
      const accountId = (await getStorageData("temp_account_id")) as string;

      setStorageData("authToken", authToken);
      setStorageData("account_id", accountId);
      
      removeStorageData("temp_authToken");
      removeStorageData("temp_account_id");
      this.goToDashboard()
    }
    else if (responseJson?.errors){
      this.setState({ isOtpError: true, otpMessage: "Code is not correct, please try one more time" })
    }
  }
  handleReFactorAuthenticationVerifyResp = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({ isOtpError: false, isresendClicked:true})
    }
    else{
      this.setState({ isOtpError: true, otpMessage: "Code not Sent Try again later" })
    }
  }
  async componentDidMount() {
    super.componentDidMount();
  }


  handleFactorAuthenticationOtp = (otpnumber: string) => {
    this.setState({ otpMessage: "", isOtpError: false })
    const otpRegex = /^[a-zA-Z0-9]+$/;
    if (otpnumber.match(otpRegex)) {
      this.setState({ otpFactNumber: otpnumber });
    } else {
      this.setState({ otpFactNumber: '',isOtpError:false });
    }
  }

  handleReSendOtp = () => {
    this.reSendFactOtp()
  }


  verifyFactOtp = async() => {
    if (!this.state.otpFactNumber.length) {
      this.setState({ otpMessage: "Please enter the OTP", isOtpError: true })
      return;
    }
    const token = await getStorageData("temp_authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:token
    };

    const httpbody = {data:{otp_code: this.state.otpFactNumber}}

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.verifyOtpFactorCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.factorAuthentication
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  goToDashboard = () => {
    const msgType: Message 
    = new Message(getName(MessageEnum.NavigationMessage));
    msgType.addData(
      getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msgType.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = 
    new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(
      getName(MessageEnum.SessionResponseData), 
    {});
    msgType.addData(
      getName(MessageEnum.NavigationRaiseMessage), 
    raiseMessage);
    this.send(msgType);
  };

  reSendFactOtp = async () => {
    const token = await getStorageData("temp_authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
       token:token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.reSendOtpFactorCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendcodeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  handleClose =()=>{
    this.setState({isresendClicked:false})
  }

  goToLogin=()=> {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

};
// Customizable Area End
