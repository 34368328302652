// Customizable Area Start
import React from "react";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import ResultTabController, { configJSON } from "./ResultTabController.web";

export class ResultTab extends ResultTabController {
    render() {

        if(this.props.state.currentTab === 1 ) {
            return (
            <div data-test-id="result-wrapper">
                {
                    this.state.tableList.result.length > 0
                    ? (
                        <TableContainer className="resultQueryHub" style={styles.viewTable}>
                            <Table data-test-id="resultTableTest" id="myTable">
                            {
                                    this.state.tableList.result.map((result, resultIndex) => (<>
                                <TableHead>
                                    <TableRow className={this.props.classes?.tableRowHeader} data-test-id="tableTestHeader">
                                        <TableCell style={styles.headerText}>
                                            {configJSON.cl_id}
                                        </TableCell>
                                        {
    this.handleGetTableName(this.flatten(result)).map((keyName, index) => 
        <TableCell style={styles.headerText} key={`keyname_${index}`}>
            {keyName}
        </TableCell>
    )
}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={this.props.classes?.tableBody} data-test-id="resultTableRowBodyTest">
                                    {this.getTableRowData(this.flatten(result)).map((itemData, index) => (
                                        
                                        <TableRow
                                        className={this.props.classes?.tableRow}
                                        style={styles.contentText}
                                        key={`tableRowResult_${index}`}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {index + 1}
                                        </TableCell>
                                        {
                                            Object.values(itemData).map((itemCell, indexCell) => (
                                                <TableCell
                                                    key={`itemCell_${indexCell}`}
                                                    component="th"
                                                    scope="row"
                                                    style={styles.contentText}
                                                >
                                                    {itemCell}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                    ))}
                                </TableBody>
                                </>
)
)}
</Table>
                        </TableContainer>
                    )
                    : (
                        <div style={styles.viewLoading}>
                            <CircularProgress />
                            <p style={styles.viewLoadingText}>{configJSON.DataLoadingLabel}</p>
                        </div>
                    )
                }
            </div>
            );
        } else return <></>
    }
}

const styles = {
    container: {
        overflowY: "auto",
        display: "flex",
        width: '100%',
    } as React.CSSProperties,
    headerViewFilterStyle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    } as React.CSSProperties,
    headerViewStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    viewLoading: {
        width: "100%",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    } as React.CSSProperties,
    viewLoadingText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        marginTop: "8px",
        textAlign: "center"
    } as React.CSSProperties,
    contentText: {
        color: "#0F172A",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        padding: "10px 0px 10px 16px",
        verticalAlign: "middle",
        textAlign: 'left',

    } as React.CSSProperties,
    headerText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#475569"
    },
    viewNameTable: {
        display: "flex",
        alignItems: "center"
    } as React.CSSProperties,
    cellIcon: {
        display: "flex",
        alignItems: "flex-end",
        borderBottom: "0px solid #fff"
    } as React.CSSProperties,
    infoCell: {
        padding: "2px 8px",
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        backgroundColor: "#F6F5F9",
    } as React.CSSProperties,
    viewTable: {
        backgroundColor: "#fff",
        height: "75%",
    },
    statusCell: {
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
    } as React.CSSProperties,
};

export default ResultTab;
// Customizable Area End
