import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { getStorageData} from "../../../../packages/framework/src/Utilities";
import Emitter from "../../../../packages/components/src/EventEmitter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  showProfileFunc?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  drawer: boolean;
  image:any;
  userName:string;
  isActive: string;
  showProfileMenu:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      drawer: false,
      isActive: "",
      image:'',
      userName:"",
      showProfileMenu:false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const profile = await getStorageData("profile_photo")
    const username = await getStorageData("username")
    this.setState({ isActive: this.props.id, image: profile, userName: username })
    Emitter.on("userName", (userName: string) => this.setState({ userName }))
    Emitter.on("profile_photo", (image: string) => this.setState({ image }))
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    // runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateToPage = (routeName: string) => {
    const msgType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msgType.addData(
      getName(MessageEnum.NavigationTargetMessage), routeName
    );
    msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    msgType.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgType);
  }

  handleClick = (type: string) => {
    if (type === 'dashboard') {
      this.setState({ isActive: 'dashboard' })
      this.navigateToPage("Dashboard")
    } else if (type === 'workspaces') {
      this.setState({ isActive: 'workspaces' })
      this.navigateToPage("WorkspaceList")
    } else if (type === 'teams') {
      this.setState({ isActive: 'teams' })
      this.navigateToPage("AccountGroups")
    }
  };


  handleShowProfile=()=>{
    this.setState({showProfileMenu:true})
    this.props.showProfileFunc()
  }

  // Customizable Area End
}
