import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { removeStorageData } from "../../framework/src/Utilities";

// Returns value based on true and false passed as first param
export function conditionalValue<T>(condition: boolean, trueValue: T, falseValue: T): T {
    return condition ? trueValue : falseValue;
}

/**
 * Handles token-related API errors by clearing auth tokens and navigating to the login page.
 * 
 * @template Props - The type of the component's `props`.
 * @param {Message} message - The API response message.
 * @param {string} getListAttachmentId - The API request identifier.
 * @param {Props} props - Component props for navigation or other actions.
 * @param {(msg: Message) => void} send - Function to send navigation messages.
 */
export const handleAuthenticationError = <Props>(
  message: Message,
  props: Props,
  send: (msg: Message) => void
) => {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

  if (
    (responseJson?.errors?.[0]?.token === "Token has Expired" || 
    responseJson?.errors?.[0]?.token === "Invalid token") && 
    apiRequestCallId
  ) {
    // Perform navigation and token cleanup
    removeStorageData("authToken");
    removeStorageData("account_id");

    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock"); // Modify Navigation target message
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    send(navigationMessage);
  }
};
