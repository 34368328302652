// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { Message } from "../../../../packages/framework/src/Message";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { UploadOptions } from "../src/DashboardController.web";

export const configJSON = require("./config");

interface SelectUploadOption {
    label: string,
    value: UploadOptions
}

export const uploadOptions: SelectUploadOption[] = [
    { label: "Upload file with no action required", value: UploadOptions.NoAction },
    { label: "Upload file with AI processing", value: UploadOptions.AIProcessing },
    { label: "Upload Greek Document with AI processing", value: UploadOptions.GreekLegalDocument },
];

export type Props = {
    isDialogOpen: boolean
    id: string;
    classes?: Record<string, string>
    onCloseDialog: (data?: { workspace_id: number; files: Array<File>; uploadChoice: UploadOptions }) => void
    navigation: any
    selectWorkspace: boolean,
}

interface WorkspaceOption {
    id: number
    name: string
}

interface S {
    files: Array<File>
    selectUploadChoice: UploadOptions
    selectedWorkspaceChoice: WorkspaceOption | null;
    listWorkspace: WorkspaceOption[]
    originalWorkspace: WorkspaceOption | null
}

interface SS {
}

class BulkUploadDashboardDialogController extends BlockComponent<Props, S, SS> {
    getListWorkspaceId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            listWorkspace: [],
            files: [],
            selectUploadChoice: UploadOptions.NoAction,
            selectedWorkspaceChoice: null,
            originalWorkspace: null
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    onDrop = (files: any) => {
        const newListFile = [...this.state.files]
        const newList = newListFile.concat(files)
        this.setState({ files: newList })
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (responseJson) {
                if (apiRequestCallId === this.getListWorkspaceId) {
                    if(this.props.selectWorkspace) {

                        const workspaceId = Number(this.props.navigation.getParam("id"));                        

                        const selectedChoice = (responseJson as WorkspaceOption[]).find((option) => option.id === workspaceId) || null
                        this.setState({ 
                            selectedWorkspaceChoice: selectedChoice, 
                            originalWorkspace: selectedChoice 
                        })
                    }
                    this.setState({ listWorkspace: responseJson })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getListWorkspace()
    }
    
    getListWorkspace = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getListWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.getListWorkspace}`
        });
    }

    closeDialog = () => {
        this.props.onCloseDialog()
        this.setState({
            files: [],
            selectUploadChoice: UploadOptions.NoAction,
            selectedWorkspaceChoice: this.state.originalWorkspace
        })
    }

    onChangeAction = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        this.setState({ selectUploadChoice: event.target.value as UploadOptions })
    }

    onReplaceFile = (acceptedFile: any, currentIndex: number) => {
        const newList = [...this.state.files]
        newList.splice(currentIndex, 1, acceptedFile[0])
        this.setState({ files: newList }) 
    }

    onRemoveFile = (currentIndex: number) => {
        const newList = [...this.state.files]
        const filterList = newList.filter((item, index) => index !== currentIndex)
        this.setState({ files: filterList }) 
    }
   
    byteConverter = (size: number) => {
        const units = ['bytes', 'KB', 'MB', 'GB'];
        let countIndex = 0;
        while(size >= 1024 && ++countIndex){
            size = size/1024;
        }
        
        return(size.toFixed(size < 10 && countIndex > 0 ? 1 : 0) + ' ' + units[countIndex]);
    }

    onChangeWorkspace = (workspaceChoice: WorkspaceOption | null) => {
        this.setState({ selectedWorkspaceChoice: workspaceChoice })
    }

    validationButton = () => {
        if (this.state.selectUploadChoice === UploadOptions.NoAction) {
            if (this.state.files.length === 0 || !this.state.selectedWorkspaceChoice) {
                return false
            } else return true
        } else {
            if (this.state.files.length === 0) {
                return false
            } else return true
        }
    }

    onContinue = () => {
        if (this.validationButton()) {
            const data = {
                workspace_id: this.state.selectedWorkspaceChoice ? this.state.selectedWorkspaceChoice.id : 0,
                files: this.state.files,
                uploadChoice: this.state.selectUploadChoice
            }
            this.props.onCloseDialog(data)
            this.setState({
                files: [],
                selectUploadChoice: UploadOptions.NoAction,
                selectedWorkspaceChoice: this.state.originalWorkspace
            })
        }
    }
}

export default BulkUploadDashboardDialogController;
// Customizable Area End
